<template>
    

<div  class="valign-wrapper">
    <div class="row valign-wrapper">
      <div class="col s12 card hoverable white " style="min-width: 30vw">


        <form>
          <div class="card-content">


<!--            Badge-->
             <div style="display: flex; align-items: center" class="row">
               <div class="col valign-wrapper">
                 <img src="/icon.png" style="max-width: 2em; margin-right: 10px; " alt="Syrian Arabic Dictionary">
                 Syrian Arabic Dictionary
               </div>
             </div>

            <div class="row">
              <div class="col s12">
                <h4>Sign in</h4>
                to continue to your dashboard
              </div>
            </div>



            <div class="row">
              <div class="col s12">
                <Editbox id="email" placeholder="Email" v-model="Email" />
              </div>
            </div>

            <div class="row">
              <div class="col s12">
                <Editbox id="password" placeholder="Password" v-model="Password" />
              </div>
            </div>


<!--            <div v-if="feedbackMessage !== ''" class="row red lighten-4 red-text text-darken-4" style="border-radius: 0.3em; border-style: solid; border-width: 0.1px">-->
<!--              {{ feedbackMessage }}-->
<!--            </div>-->

            <Alert v-if="feedbackMessage.msg !== ''" :error="feedbackMessage.error" >{{ feedbackMessage.msg }}</Alert>

          </div>
        </form>

        <div class="card-action right-align">
          <div class="btn-flat waves-effect waves-block" style="position: absolute; left: 0" @click="$router.push('/signup')">
            Create an account
          </div>
          <div class="btn z-depth-0 green waves-effect waves-light " @click="login">Log in</div>
<!--          <div class="btn z-depth-0 green waves-effect waves-light " @click="loginGoogle">Google</div>-->
        </div>


        <!--END OF CARD-->
      </div>


    </div>

  <a href="#" @click="loginGoogle" class="black-text btn-flat transparent waves-effect" style="position: absolute; top: calc(100vh - 10em); left: calc(50vw - 9em)">Or sign in with Google <img src="https://www.google.com/s2/favicons?domain=www.google.com" ></a>

</div>
    
</template>

<script>
import Editbox from "../components/Controls/Editbox";
import Alert from "../components/Controls/Alert";

export default {
  name: "Login",
  components: {Editbox, Alert},
  data(){
    return {
      Email: "",
      Password: "",
    }
  },
  computed:{
    feedbackMessage(){
      return this.$store.state.auth.cacheMsg
    }
  },
  created(){
    // clear cache like message
    this.$store.dispatch("authRefresh");
  },
  methods: {
    login(){
      // make sure not already logged in


        // dispatch login
        this.$store.dispatch('authLogin', {email: this.Email, password: this.Password})

    },
    loginGoogle(){
      this.$store.dispatch('authGoogleLogin')
    }

  },
  mounted() {

  }
}
</script>
